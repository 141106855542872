var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Portlet',{staticClass:"audit-logs mt-4",attrs:{"title":("Audit Logs (" + _vm.auditLogsCount + ")"),"icon":"clipboard-list"}},[_c('template',{slot:"buttons"},[_c('font-awesome-icon',{staticClass:"mr-1",staticStyle:{"cursor":"pointer"},attrs:{"icon":_vm.isAccordionOpen ? 'minus' : 'plus',"data-toggle":"collapse","href":".databaseAuditLogsList"},on:{"click":_vm.changeAccordionState}}),_c('div',{staticClass:"clearfix"})],1),_c('div',{class:['databaseAuditLogsList', {'collapse': _vm.collapsed}]},[(_vm.loading)?_c('LoadingPlaceholder'):[(_vm.audits)?[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.audits),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.audits),"sortable":{
            allowUnsort: _vm.kgm_allowUnsort,
            mode: _vm.kgm_sortMode
          },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"installationUrl",fn:function(ref){
          var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.entity == 'TerminalVnc')?_c('router-link',{staticClass:"alt-primary-color",attrs:{"to":("/installation/" + (props.dataItem.installationId) + "/devices/device/" + (props.dataItem.entityId))}},[_vm._v(" "+_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem))+" ")]):_c('router-link',{staticClass:"alt-primary-color",attrs:{"to":("/installation/" + (props.dataItem.installationId) + "/software-components/" + (props.dataItem.parentId))}},[_vm._v(" "+_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem))+" ")])],1)]}},{key:"idFilter",fn:function(ref){
          var props = ref.props;
          var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"idFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"Filter by Id"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('idFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"logTypeFilter",fn:function(ref){
          var props = ref.props;
          var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"logTypeFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"Filter by Type"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('logTypeFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"logDateFilter",fn:function(ref){
          var props = ref.props;
          var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"logDateFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"date","placeholder":"Filter by Date"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('logDateFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"systemFilter",fn:function(ref){
          var props = ref.props;
          var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"systemFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"Filter by System"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('systemFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"entityFilter",fn:function(ref){
          var props = ref.props;
          var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"entityFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"Filter by Entity"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('entityFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"entityIdFilter",fn:function(ref){
          var props = ref.props;
          var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"entityIdFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"Filter by Entity Id"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('entityIdFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"usernameFilter",fn:function(ref){
          var props = ref.props;
          var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"usernameFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"Filter by Username"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('usernameFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"optionsTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{class:props.className},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.openChangesModal(props.dataItem)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"edit"}}),_c('span',[_vm._v("Changes")])],1)])]}},{key:"dateTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.dateTime_fromNow(_vm.kgm_getNestedValue(props.field, props.dataItem))),expression:"dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem))"}]},[_vm._v(_vm._s(_vm.dateTime_dateTime(_vm.kgm_getNestedValue(props.field, props.dataItem))))])])]}}],null,false,745511343)})]:_vm._e(),_c('SweetModal',{ref:"auditChanges",staticClass:"overflowHidden",attrs:{"title":'Rollback Changes'},on:{"close":_vm.resetSelectedAuditLog}},[(_vm.selectedAuditLog)?[_c('ConfigurationCompare',{attrs:{"current-config":_vm.oldConf,"previous-config":_vm.newConf}})]:_vm._e(),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.$refs.auditChanges.close()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"times"}}),_vm._v(_vm._s(_vm.$t('cancel'))+" ")],1),(!_vm.isInstallationChangelog && _vm.selectedAuditLog != null && _vm.selectedAuditLog.logType != 'View' && _vm.selectedAuditLog.logType != 'Login' && _vm.selectedAuditLog.logType != 'SessionDelete')?_c('button',{staticClass:"btn btn-danger float-right mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.rollbackObject()}},slot:"button"},[_vm._v(" Rollback ")]):_vm._e(),_c('div',{staticClass:"clearfix"})],2)]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }